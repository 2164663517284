import * as React from "react";

import { Link } from "gatsby";

import Layout from "../components/Layout";
import useTheme from "../hook/useTheme";

// markup
const NotFoundPage = () => {
  const theme = useTheme(7);

  return (
    <Layout theme={theme} pageTitle={"404"}>
      <div className="my-12 flex min-h-70-screen w-full justify-center px-4 md:px-8">
        <section className="flex w-full max-w-4xl flex-col items-start justify-center">
          <h1 className="my-4 rounded-lg bg-white px-6 py-4 text-3xl md:px-8 md:py-6 md:text-4xl">
            Nie znaleziono :(
          </h1>
          <p className="my-4 flex h-56 w-full flex-col justify-between rounded-lg bg-white px-6 py-4 text-xl md:px-8 md:py-6 md:text-2xl">
            <span>Strona może być w budowie.</span>
            <Link
              to="/"
              className="mx-auto my-6 w-64 rounded-full bg-white px-8 py-4 text-center font-bold text-gray-800 shadow-lg transition duration-300 ease-in-out hover:scale-105 focus:outline-none lg:mx-0"
            >
              Strona główna
            </Link>
          </p>
        </section>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
