import { useContext } from "react";

import { ThemeContext } from "../context/ThemeContext";

const useTheme = (variant) => {
  const { themesLayout, themesLayoutContrast, isHeightContrastEnable } =
    useContext(ThemeContext);

  return isHeightContrastEnable
    ? themesLayoutContrast[0]
    : themesLayout[variant];
};

export default useTheme;
